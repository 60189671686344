<template>
   <div class="row">
      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row m-0">
                  <div class="col-lg-2 col-12 px-1 mb-3">
                     <label><i class="far fa-filter color-theme me-1"></i> {{ $t('admGerenciar.filtro') }}</label>
                     <v-select title="Desktop" :clearable="false" :options="$t('admGerenciar.filtros')" v-model="pesquisa.tipo" label="value" :reduce="f => f.key" />
                     <select title="Mobile" class="form-control" v-model="pesquisa.tipo">
                        <option v-for="(option, index) in $t('admGerenciar.filtros')" :key="index" :value="option.key">{{ option.value }}</option>
                     </select>
                  </div>
                  <div class="col-lg-7 col-12 px-1 mb-3">
                     <label><i class="far fa-search color-theme me-1"></i> {{ $t('admGerenciar.pesquisar') }}</label>
                     <input type="text" class="form-control" v-model="pesquisa.nome" :placeholder="$t('admGerenciar.pesquisarDesc')" @keyup.enter="search" />
                  </div>
                  <div class="col-lg-1 col-6 px-1 mb-3 align-self-end">
                     <button type="button" class="btn btn-primary w-100" @click="search">
                        <i class="far fa-search font-12"></i>
                     </button>
                  </div>
                  <div class="col-lg-2 col-6 px-1 mb-3 align-self-end">
                     <button type="button" class="btn btn-primary w-100" @click="adicionar">
                        <i class="far fa-plus font-12 me-1"></i> {{ $t('admGerenciar.adicionar') }}
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <!-- lista de pacotes -->
      <div class="col-12" v-if="pesquisa.resultado.length > 0 && pesquisa.tipo == 'Pacotes'">
         <div class="cards-2">
            <pacote v-for="(pacote, index) in pesquisa.resultado" :key="index" :pacote="pacote" :index="index" :tipo="'Pacotes'"
               @deletar="deletarPacote($event)" @editar="abrirModalPacote($event)"/>
         </div>
      </div>

      <!-- lista de cursos -->
      <div class="col-12" v-else-if="pesquisa.resultado.length > 0 && pesquisa.tipo == 'Cursos'">
         <div class="cards-2">
            <pacote v-for="(pacote, index) in pesquisa.resultado" :key="index" :pacote="pacote" :index="index" :tipo="'Cursos'"
               @abrir="abrirModulo($event)" @deletar="deleteCurso($event)" @editar="abrirModalCurso($event)"/>
         </div>
      </div>

      <!-- lista de scripts -->
      <div class="col-12" v-else-if="pesquisa.resultado.length > 0 && pesquisa.tipo == 'Scripts'">
         <div class="cards-2">
            <pacote v-for="(pacote, index) in pesquisa.resultado" :key="index" :pacote="pacote" :index="index" :tipo="'Scripts'"
               @editar="abrirModalScript($event)" @deletar="deleteScript($event)"/>
         </div>
      </div>

      <!-- modalPacote -->
      <div class="modal fade" id="modalPacote" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalPacoteLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalPacoteLabel" v-if="pesquisa.resultado != null && modalPacote.id != null">{{ $t('admGerenciar.editPacoteTitulo') }}</h5>
                  <h5 class="modal-title" id="modalPacoteLabel" v-else>{{ $t('admGerenciar.addPacoteTitulo') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0">
                     <div class="col-lg-7 px-1 mb-2">
                        <div class="card h-100 mb-0">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <div class="d-flex justify-content-between">
                                       <h6 class="text-center mb-0">{{ $t('admGerenciar.infoPacoteTitulo') }}</h6>
                                       <h6 class="mb-0">{{ valorRecomendado.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}) }}</h6>
                                    </div>
                                    <hr class="mb-2">
                                 </div>
                                 <div class="col-7 mb-3 px-1">
                                    <label><i class="far fa-file color-theme me-1"></i> {{ $t('admGerenciar.campoTitulo') }}</label>
                                    <input type="text" class="form-control" maxlength="200" v-model="modalPacote.nome" />
                                 </div>
                                 <div class="col-5 mb-3 px-1">
                                    <label><i class="far fa-dollar-sign color-theme me-1"></i> {{ $t('admGerenciar.campoValor') }}</label>
                                    <input type="text" class="form-control" v-mask="['#.##', '##.##', '###.##', '####.##']" v-model="modalPacote.valor" />
                                 </div>
                                 <div class="col-12 px-1 mb-3">
                                    <label class="mb-1"><i class="far fa-tag color-theme me-1"></i> {{ $t('admGerenciar.campoDescricao') }}</label>
                                    <textarea style="height: 100px;" class="form-control" maxlength="1000" v-model="modalPacote.descricao"></textarea>
                                 </div>
                                 <div class="col-12 px-1 mb-3">
                                    <label><i class="far fa-stream color-theme me-1"></i> {{ $t('admGerenciar.campoCategorias') }}</label>
                                    <v-select multiple v-model="modalPacote.tipo" :options="$t('categorias')" :clearable="false" class="select-multiple" label="value" :reduce="c => c.key" />
                                 </div>
                                 <div class="col-12 px-1 mb-3">
                                    <label><i class="far fa-file color-theme me-1"></i> {{ $t('admGerenciar.campoLinkDocumentacao') }}</label>
                                    <input type="text" class="form-control" maxlength="200" v-model="modalPacote.linkDocumentacao" />
                                 </div>
                                 <div class="col-7 px-1 mb-3">
                                    <label><i class="far fa-user color-theme me-1"></i> {{ $t('admGerenciar.campoDono') }}</label>
                                    <v-select title="Desktop" :clearable="false" :options="listaDonosScript" v-model="modalPacote.idDono" label="nome" :reduce="d => d.id" />
                                    <select title="Mobile" class="form-control" v-model="modalPacote.idDono">
                                       <option v-for="(option, index) in listaDonosScript" :key="index" :value="option.id">{{ option.nome }}</option>
                                    </select>
                                 </div>
                                 <div class="col-5 px-1 mb-3">
                                    <label><i class="far fa-sort-numeric-down-alt color-theme me-1"></i> {{ $t('admGerenciar.campoOrdenacao') }}</label>
                                    <input type="text" class="form-control" v-mask="'##########'" v-model="modalPacote.ordenacao" />
                                 </div>
                                 <div class="col-6 px-1">
                                    <div class="form-check mb-0">
                                       <input type="checkbox" class="form-check-input" id="pacoteIsPromocao" v-model="modalPacote.isPromocao">
                                       <label class="form-check-label cursor-pointer" for="pacoteIsPromocao"><i class="far fa-percent color-theme mx-1"></i> {{ $t('admGerenciar.campoPromocao') }}</label>
                                    </div>
                                 </div>
                                 <div class="col-6 px-1">
                                    <div class="form-check mb-0">
                                       <input type="checkbox" class="form-check-input" id="pacoteIsDesabilitado" v-model="modalPacote.isDesabilitado">
                                       <label class="form-check-label cursor-pointer" for="pacoteIsDesabilitado"><i class="far fa-eye color-theme mx-1"></i> {{ $t('admGerenciar.campoDesabilitado') }}</label>
                                    </div>
                                 </div>
                                 <div class="col-6 px-1">
                                    <div class="form-check mb-0">
                                       <input type="checkbox" class="form-check-input" id="pacoteIsDestaque" v-model="modalPacote.isDestaque">
                                       <label class="form-check-label cursor-pointer" for="pacoteIsDestaque"><i class="far fa-star color-theme mx-1"></i> {{ $t('admGerenciar.campoDestaque') }}</label>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-5 px-1 mb-2">
                        <div class="card h-100 mb-0">
                           <div class="card-body mb-2 pb-1">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">{{ $t('admGerenciar.campoBanner') }} <small class="text-secondary">(3x4)</small></h6>
                                    <hr class="mb-2">
                                 </div>
                                 <div class="col-12 px-1" v-show="String(modalPacote.nomeArquivo).startsWith('http')">
                                    <div class="input-group">
                                       <input type="text" class="form-control" v-model="modalPacote.nomeArquivo" />
                                       <a href="javascript:;" class="input-group-text" @click="modalPacote.nomeArquivo = '';"><i class="far fa-trash text-danger font-13"></i></a>
                                    </div>
                                 </div>
                                 <div class="col-12 px-1" v-show="!String(modalPacote.nomeArquivo).startsWith('http')">
                                    <input type="file" class="form-control" accept=".doc,.docx,.pdf,.jpeg,.png,.jpg" ref="pacoteFiles" />
                                 </div>
                                 <div class="col-12 px-1 mt-2" v-if="String(modalPacote.nomeArquivo).startsWith('http')">
                                    <img width="100%" height="100%" class="rounded" :src="modalPacote.nomeArquivo" @error="imageError" />
                                 </div>
                                 <div class="col-12 px-1 my-5 text-center" v-else>{{ $t('admGerenciar.nenhumBanner') }}</div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 px-1 mb-2">
                        <div class="card mb-0">
                           <div class="card-body">
                              <label><i class="far fa-quote-right color-theme me-1"></i> {{ $t('admGerenciar.campoConteudo') }}</label>
                              <JoditEditor :value="modalPacote.conteudo ? modalPacote.conteudo : ''" @attValue="modalPacote.conteudo = $event" />
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6 px-1 mb-2">
                        <div class="card h-100 mb-0">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <div class="d-flex justify-content-between">
                                       <h6 class="mb-0">{{ $t('admGerenciar.campoCursos') }}</h6>
                                       <h6 class="mb-0">{{ modalPacote.modulos.length }} {{ $t('admGerenciar.campoDescQtdCursos') }}</h6>
                                    </div>
                                    <hr class="mb-2">
                                 </div>
                                 <div class="col-12 px-1" v-if="modalPacote.modulos.length > 0">
                                    <div class="cards-5">
                                       <div class="imageContainer" @click="vinculo(modulo, modalPacote)" v-for="(modulo, index) in modalPacote.modulos" :key="index">
                                          <img :title="modulo.nome" class="rounded cursoVinc" :class="modulo.vinculo == true ? 'borderSelect': 'borderNotSelect'" :src="modulo.nomeArquivo == null ? '' : modulo.nomeArquivo" @error="imageError">
                                          <i class="far fa-link text-success" v-if="modulo.vinculo == true"></i>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-12 px-1 my-5 text-center" v-else>{{ $t('admGerenciar.nenhumCurso') }}</div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6 px-1 mb-2">
                        <div class="card h-100 mb-0">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <div class="d-flex justify-content-between">
                                       <h6 class="text-center mb-0">{{ $t('admGerenciar.campoScripts') }}</h6>
                                       <h6 class="mb-0">{{ modalPacote.produtos.length }} {{ $t('admGerenciar.campoDescQtdScripts') }}</h6>
                                    </div>
                                    <hr class="mb-2">
                                 </div>
                                 <div class="col-12 px-1" v-if="modalPacote.produtos.length > 0">
                                    <div class="cards-5">
                                       <div class="imageContainer" @click="vinculo(produto, modalPacote)" v-for="(produto, index) in modalPacote.produtos" :key="index">
                                          <img :title="produto.nome" class="rounded cursoVinc" :class="produto.vinculo == true ? 'borderSelect': 'borderNotSelect'" :src="produto.nomeArquivo == null ? '' : produto.nomeArquivo" @error="imageError">
                                          <i class="far fa-link text-success" v-if="produto.vinculo == true"></i>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-12 px-1 my-5 text-center" v-else>{{ $t('admGerenciar.nenhumScript') }}</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="savePacote">{{ $t('admGerenciar.botaoSalvar') }}</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('admGerenciar.botaoFechar') }}</button>
               </div>
            </div>
         </div>
      </div>

      <!-- modalCurso -->
      <div class="modal fade" id="modalCurso" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalCursoLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalCursoLabel" v-if="pesquisa.resultado != null && modalModulo.id != null">{{ $t('admGerenciar.editCursoTitulo') }}</h5>
                  <h5 class="modal-title" id="modalCursoLabel" v-else>{{ $t('admGerenciar.addCursoTitulo') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0">
                     <div class="col-lg-7 px-1 mb-2">
                        <div class="card h-100 mb-0">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">{{ $t('admGerenciar.infoCursoTitulo') }}</h6>
                                    <hr class="mb-2">
                                 </div>
                                 <div class="col-7 mb-3 px-1">
                                    <label><i class="far fa-file color-theme me-1"></i> {{ $t('admGerenciar.campoTitulo') }}</label>
                                    <input type="text" class="form-control" maxlength="200" v-model="modalModulo.nome"/>
                                 </div>
                                 <div class="col-5 mb-3 px-1">
                                    <label><i class="far fa-dollar-sign color-theme me-1"></i> {{ $t('admGerenciar.campoValor') }}</label>
                                    <input type="text" class="form-control" v-mask="['#.##', '##.##', '###.##', '####.##']" maxlength="7" v-model="modalModulo.valor"/>
                                 </div>
                                 <div class="col-12 mb-3 px-1">
                                    <label><i class="far fa-hashtag color-theme me-1"></i> {{ $t('admGerenciar.campoCodigo') }}</label>
                                    <input type="text" class="form-control" maxlength="10" v-model="modalModulo.codigo"/>
                                 </div>
                                 <div class="col-12 px-1">
                                    <label class="mb-1"><i class="far fa-tag color-theme me-1"></i> {{ $t('admGerenciar.campoDescricao') }}</label>
                                    <textarea style="height: 200px;" class="form-control" maxlength="1000" v-model="modalModulo.descricao"></textarea>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div class="col-lg-5 px-1 mb-2">
                        <div class="card h-100 mb-0">
                           <div class="card-body mb-2 pb-1">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">{{ $t('admGerenciar.campoBanner') }} <small class="text-secondary">(3x4)</small></h6>
                                    <hr class="mb-2">
                                 </div>
                                 <div class="col-12 px-1" v-show="String(modalModulo.nomeArquivo).startsWith('http')">
                                    <div class="input-group">
                                       <input type="text" class="form-control" v-model="modalModulo.nomeArquivo" />
                                       <a href="javascript:;" class="input-group-text" @click="modalModulo.nomeArquivo = '';"><i class="far fa-trash text-danger font-13"></i></a>
                                    </div>
                                 </div>
                                 <div class="col-12 px-1" v-show="!String(modalModulo.nomeArquivo).startsWith('http')">
                                    <input type="file" class="form-control" accept=".doc,.docx,.pdf,.jpeg,.png,.jpg" ref="cursoFiles" />
                                 </div>
                                 <div class="col-12 mt-2" v-if="String(modalModulo.nomeArquivo).startsWith('http')">
                                    <img width="100%" height="100%" class="rounded" :src="modalModulo.nomeArquivo" @error="imageError" />
                                 </div>
                                 <div class="col-12 px-1 my-5 text-center" v-else>{{ $t('admGerenciar.nenhumBanner') }}</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="saveCurso">{{ $t('admGerenciar.botaoSalvar') }}</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('admGerenciar.botaoFechar') }}</button>
               </div>
            </div>
         </div>
      </div>

      <!-- modalScript -->
      <div class="modal fade" id="modalScript" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalScriptLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalScriptLabel" v-if="pesquisa.resultado != null && modalScript.id != null">{{ $t('admGerenciar.editScriptTitulo') }}</h5>
                  <h5 class="modal-title" id="modalScriptLabel" v-else>{{ $t('admGerenciar.addScriptTitulo') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true"></span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0">
                     <div class="col-lg-7 px-1 mb-2">
                        <div class="card h-100 mb-0">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">{{ $t('admGerenciar.infoScriptTitulo') }}</h6>
                                    <hr class="mb-2">
                                 </div>
                                 <div class="col-7 mb-3 px-1">
                                    <label><i class="far fa-file color-theme me-1"></i> {{ $t('admGerenciar.campoTitulo') }}</label>
                                    <input type="text" class="form-control" maxlength="200" v-model="modalScript.nome"/>
                                 </div>
                                 <div class="col-5 mb-3 px-1">
                                    <label><i class="far fa-dollar-sign color-theme me-1"></i> {{ $t('admGerenciar.campoValor') }}</label>
                                    <input type="text" class="form-control" v-mask="['#.##', '##.##', '###.##', '####.##']" maxlength="7" v-model="modalScript.valor"/>
                                 </div>
                                 <div class="col-12 px-1 mb-3">
                                    <label class="mb-1"><i class="far fa-tag color-theme me-1"></i> {{ $t('admGerenciar.campoDescricao') }}</label>
                                    <textarea style="height: 100px;" class="form-control" maxlength="1000" v-model="modalScript.descricao"></textarea>
                                 </div>
                                 <div class="col-12 px-1 mb-3">
                                    <label><i class="far fa-stream color-theme me-1"></i> {{ $t('admGerenciar.campoCategorias') }}</label>
                                    <v-select multiple v-model="modalScript.tipo" :options="$t('categorias')" :clearable="false" class="select-multiple" label="value" :reduce="c => c.key" />
                                 </div>
                                 <div class="col-sm-6 mb-3 px-1">
                                    <label><i class="fab fa-google-drive color-theme me-1"></i> {{ $t('admGerenciar.campoLinkDrive') }}</label>
                                    <input type="text" class="form-control" maxlength="200" v-model="modalScript.linkDriver"/>
                                 </div>
                                 <div class="col-sm-6 mb-3 px-1">
                                    <label><i class="far fa-file-alt color-theme me-1"></i> {{ $t('admGerenciar.campoLinkDocumentacao') }}</label>
                                    <input type="text" class="form-control" maxlength="200" v-model="modalScript.linkDocumentacao"/>
                                 </div>
                                 <div class="col-sm-6 mb-3 px-1">
                                    <label><i class="far fa-user color-theme me-1"></i> {{ $t('admGerenciar.campoLoginSvn') }}</label>
                                    <input type="text" class="form-control" maxlength="200" v-model="modalScript.loginSvn"/>
                                 </div>
                                 <div class="col-sm-6 mb-3 px-1 position-relative">
                                    <label><i class="far fa-lock color-theme me-1"></i> {{ $t('admGerenciar.campoSenhaSvn') }}</label>
                                    <input :type="showPassword ? 'text' : 'password'" class="form-control" maxlength="200" v-model="modalScript.senhaSvn"/>
                                    <span @click="showPassword = !showPassword;" class="toggle-password">
                                       <i v-if="showPassword" class="far fa-eye"></i>
                                       <i v-else class="far fa-eye-slash"></i>
                                    </span>  
                                 </div>
                                 <div class="col-12 mb-3 px-1">
                                    <label><i class="far fa-turtle color-theme me-1"></i> {{ $t('admGerenciar.campoLinkSvn') }}</label>
                                    <input type="text" class="form-control" maxlength="200" v-model="modalScript.linkSvn" />
                                 </div>
                                 <div class="col-7 px-1 mb-3">
                                    <label><i class="far fa-user color-theme me-1"></i> {{ $t('admGerenciar.campoDono') }}</label>
                                    <v-select title="Desktop" :clearable="false" :options="listaDonosScript" v-model="modalScript.idDono" label="nome" :reduce="d => d.id" />
                                    <select title="Mobile" class="form-control" v-model="modalScript.idDono">
                                       <option v-for="(option, index) in listaDonosScript" :key="index" :value="option.id">{{ option.nome }}</option>
                                    </select>
                                 </div>
                                 <div class="col-5 px-1 mb-3">
                                    <label><i class="far fa-sort-numeric-down-alt color-theme me-1"></i> {{ $t('admGerenciar.campoOrdenacao') }}</label>
                                    <input type="text" class="form-control" v-mask="'##########'" v-model="modalScript.ordenacao" />
                                 </div>
                                 <div class="col-6 px-1">
                                    <div class="form-check mb-0">
                                       <input type="checkbox" class="form-check-input" id="scriptIsPromocao" v-model="modalScript.isPromocao">
                                       <label class="form-check-label cursor-pointer" for="scriptIsPromocao"><i class="far fa-percent color-theme mx-1"></i> {{ $t('admGerenciar.campoPromocao') }}</label>
                                    </div>
                                 </div>
                                 <div class="col-6 px-1">
                                    <div class="form-check mb-0">
                                       <input type="checkbox" class="form-check-input" id="scriptIsDesabilitado" v-model="modalScript.isDesabilitado">
                                       <label class="form-check-label cursor-pointer" for="scriptIsDesabilitado"><i class="far fa-eye color-theme mx-1"></i> {{ $t('admGerenciar.campoDesabilitado') }}</label>
                                    </div>
                                 </div>
                                 <div class="col-6 px-1">
                                    <div class="form-check mb-0">
                                       <input type="checkbox" class="form-check-input" id="scriptIsDestaque" v-model="modalScript.isDestaque">
                                       <label class="form-check-label cursor-pointer" for="scriptIsDestaque"><i class="far fa-star color-theme mx-1"></i> {{ $t('admGerenciar.campoDestaque') }}</label>
                                    </div>
                                 </div>
                                 <div class="col-6 px-1">
                                    <div class="form-check mb-0">
                                       <input type="checkbox" class="form-check-input" id="scriptIsExclusivo" v-model="modalScript.isExclusivo">
                                       <label class="form-check-label cursor-pointer" for="scriptIsExclusivo"><i class="far fa-sparkles color-theme mx-1"></i> {{ $t('admGerenciar.campoExclusivo') }}</label>
                                    </div>
                                 </div>
                                 <div class="col-6 px-1">
                                    <div class="form-check mb-0">
                                       <input type="checkbox" class="form-check-input" id="scriptIsOtimizado" v-model="modalScript.isOtimizado">
                                       <label class="form-check-label cursor-pointer" for="scriptIsOtimizado"><i class="far fa-eraser color-theme mx-1"></i> {{ $t('admGerenciar.campoOtimizado') }}</label>
                                    </div>
                                 </div>
                                 <div class="col-6 px-1">
                                    <div class="form-check mb-0">
                                       <input type="checkbox" class="form-check-input" id="scriptIsTestado" v-model="modalScript.isTestado">
                                       <label class="form-check-label cursor-pointer" for="scriptIsTestado"><i class="far fa-check-double color-theme mx-1"></i> {{ $t('admGerenciar.campoTestado') }}</label>
                                    </div>
                                 </div>
                                 <div class="col-6 px-1">
                                    <div class="form-check mb-0">
                                       <input type="checkbox" class="form-check-input" id="scriptIsSeguro" v-model="modalScript.isSeguro">
                                       <label class="form-check-label cursor-pointer" for="scriptIsSeguro"><i class="far fa-shield color-theme mx-1"></i> {{ $t('admGerenciar.campoSeguro') }}</label>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-5 px-1 mb-2">
                        <div class="card h-100 mb-0">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">{{ $t('admGerenciar.campoBanner') }} <small class="text-secondary">(3x4)</small></h6>
                                    <hr class="mb-2">
                                 </div>
                                 <div class="col-12 px-1" v-show="String(modalScript.nomeArquivo).startsWith('http')">
                                    <div class="input-group">
                                       <input type="text" class="form-control" v-model="modalScript.nomeArquivo" />
                                       <a href="javascript:;" class="input-group-text" @click="modalScript.nomeArquivo = '';"><i class="far fa-trash text-danger font-13"></i></a>
                                    </div>
                                 </div>
                                 <div class="col-12 px-1" v-show="!String(modalScript.nomeArquivo).startsWith('http')">
                                    <input type="file" class="form-control" accept=".doc,.docx,.pdf,.jpeg,.png,.jpg" ref="scriptFile" />
                                 </div>
                                 <div class="col-12 px-1 mt-2" v-if="String(modalScript.nomeArquivo).startsWith('http')">
                                    <img width="100%" height="100%" class="rounded border border-secondary" :src="modalScript.nomeArquivo" @error="imageError" />
                                 </div>
                                 <div class="col-12 px-1 my-5 text-center" v-else>{{ $t('admGerenciar.nenhumBanner') }}</div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 px-1 mb-2">
                        <div class="card mb-0">
                           <div class="card-body">
                              <label><i class="far fa-quote-right color-theme me-1"></i> {{ $t('admGerenciar.campoConteudo') }}</label>
                              <JoditEditor :value="modalScript.conteudo ? modalScript.conteudo : ''" @attValue="modalScript.conteudo = $event" />
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6 px-1 mb-2">
                        <div class="card h-100 mb-0">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">{{ $t('admGerenciar.campoVideo') }}</h6>
                                    <hr class="mb-2">
                                 </div>
                                 <div class="col-12 px-1 mb-3">
                                    <label><i class="far fa-file color-theme me-1"></i> Link</label>
                                    <input type="text" class="form-control" placeholder="Src do Iframe" maxlength="500" v-model="modalScript.link" />
                                 </div>
                                 <div class="col-12 px-1" v-if="String(modalScript.link).startsWith('https://www.youtube.com/embed/') || String(modalScript.link).startsWith('https://iframe.mediadelivery.net/embed/')">
                                    <div class="ratio ratio-16x9">
                                       <iframe class="rounded" width="100%" height="400" :src="modalScript.link" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media;" allowfullscreen></iframe>
                                    </div>
                                 </div>
                                 <div class="col-12 px-1 my-5 text-center" v-else>{{ $t('admGerenciar.nenhumVideo') }}</div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6 px-1 mb-2">
                        <div class="card h-100 mb-0">
                           <div class="card-body mb-2 pb-1">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">{{ $t('admGerenciar.campoImagens') }} <small class="text-secondary">(1x1)</small></h6>
                                    <hr class="mb-2">
                                 </div>
                                 <div class="col-12 px-1 mb-2" v-show="modalScript.listFotos == null || modalScript.listFotos.length < 3">
                                    <input type="file" class="form-control" multiple accept=".doc,.docx,.pdf,.jpeg,.png,.jpg" ref="imagemFile" />
                                 </div>
                                 <div class="col-12 px-1">
                                    <div class="cards-4">
                                       <div class="imageContainer" v-for="(foto, index) in modalScript.listFotos" :key="index">
                                          <img class="rounded cursoVinc" :src="foto.urlImage == null ? '' : foto.urlImage" @error="imageError">
                                          <i class="far fa-trash text-danger cursor-pointer" @click="removeImage(index)"></i>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="saveScript">{{ $t('admGerenciar.botaoSalvar') }}</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('admGerenciar.botaoFechar') }}</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import JoditEditor from '@/components/admin/EditorHTML.vue'
import pacote from '@/components/admin/gerenciar/Pacote.vue'

export default {
	name: 'Gerenciar',
	data: function() {
		return {
         valorRecomendado: 0,
         pesquisa: {'nome': '', 'tipo': 'Pacotes', 'resultado': []},
			modalPacote: {
            'id': null, 'nome': null, 'descricao': null, 'valor': '0.00', 'nomeArquivo': null, 'tipo': [], 'isPromocao': false, 'isDesabilitado': false, 'isDestaque': false, 
            'tags': null, 'qtdVendidos': 0, 'data': null, 'linkDocumentacao': null, 'urlImage': null, 'jaPossuiPacote': false, 'qtdModulos': 0, 'qtdProdutos': 0, 
            'totalViews': 0, 'modulos': [], 'produtos': [], 'idDono': null, 'ordenacao': 0, 'conteudo': ''
         },
			modalModulo: {
            'id': null, 'nome': null, 'codigo': null, 'pontuacao': 0, 'qtdVotos': 0, 'descricao': null, 'nomeArquivo': null, 'valor': '0.00', 'urlImage': null, 
            'media': 5, 'vinculo': false, 'blocos': []
         },
			modalScript: {
            'link': null, 'linkDriver': null, 'valor': '0.00', 'nome': null, 'senhaSvn': null, 'descricao': null, 'loginSvn': null, 'listFotos': [], 
            'vinculo': false, 'id': null, 'linkSvn': null, 'linkDocumentacao': null, 'urlImage': null, 'isPromocao': false, 'isDesabilitado': false, 'isDestaque': false,
            'isExclusivo': false, 'isOtimizado': false, 'isTestado': false, 'isSeguro': false, 'tipo': [], 'idDono': null, 'ordenacao': 0, 'conteudo': ''
         },
         showPassword: false
		}
	},
   watch: {
      'pesquisa.tipo': function (novoValor) {
         localStorage.setItem('ultimaOpcaoSelecionada', novoValor);
         this.search()
      }
   },
	computed: {
		...mapState({
			listaDonosScript: state => state.listaDonosScript,
			dadosUsuario: state => state.dadosUsuario,
			listaScripts: state => state.listaScripts,
			listaCursos: state => state.listaCursos,
         urlRest: state => state.urlRest
		}),
	},
	components: {
      pacote, JoditEditor
   },
	methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      search : function () {
         if (this.pesquisa.tipo == 'Pacotes') {
            this.searchPacotes()
         } else if (this.pesquisa.tipo == 'Cursos') {
            this.searchCursos()
         } else if (this.pesquisa.tipo == 'Scripts') {
            this.searchScripts()
         }
      },
      adicionar : function () {
         if (this.pesquisa.tipo == 'Pacotes') {
            this.abrirModalPacote()
         } else if (this.pesquisa.tipo == 'Cursos') {
            this.abrirModalCurso()
         } else if (this.pesquisa.tipo == 'Scripts') {
            this.abrirModalScript()
         }
      },
		searchPacotes : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicosAdmin/searchPacotes',
            params: {
               nome: this.pesquisa.nome
            }
         }).then(response => {
            this.pesquisa.resultado = response.data.resultado

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t('sessaoExpirada')
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
		savePacote : function () {
         if (this.modalPacote.nome == null || this.modalPacote.nome.trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: this.$t('admGerenciar.validacaoTitulo')
            })

            return
         } else if (this.modalPacote.tipo == null || this.modalPacote.tipo.length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: this.$t('admGerenciar.validacaoCategoria')
            })

            return
         } else if (this.modalPacote.valor == null || this.modalPacote.valor.trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: this.$t('admGerenciar.validacaoValor')
            })

            return
         }

         let dataForm = new FormData();
         dataForm.append('info', JSON.stringify(this.modalPacote));

         for (let file of this.$refs.pacoteFiles.files) {
            if (file.size < 10485760) {
               dataForm.append('banner', file, String(file.name).replace(/[^\w\s.]/gi, ''));
            } else {
               Swal.fire({
                  icon: 'error',
                  title: this.$t('admGerenciar.validacaoTamanhoArquivo'),
                  text: this.$t('admGerenciar.validacaoTamanhoArquivoDesc')
               });
               
               return
            }
         }

         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicosAdmin/savePacote',
            headers: {
					'Content-Type': 'multipart/form-data'
            },
				data: dataForm

         }).then(() => {
            this.searchPacotes()

            this.$toast.fire({
               icon: 'success',
               title: this.$t('admGerenciar.pacoteSalvoSucesso')
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t('sessaoExpirada')
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            $('#modalPacote').modal('hide')

            this.$refs.pacoteFiles.value = null;
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
		abrirModalPacote : function (pacote) {
         this.valorRecomendado = 0
         this.modalPacote = {
            'id': null, 'nome': null, 'descricao': null, 'valor': '0.00', 'nomeArquivo': null, 'tipo': [], 'isPromocao': false, 'isDesabilitado': false, 'isDestaque': false, 
            'tags': null, 'qtdVendidos': 0, 'data': null, 'linkDocumentacao': null, 'urlImage': null, 'jaPossuiPacote': false, 'qtdModulos': 0, 'qtdProdutos': 0, 
            'totalViews': 0, 'modulos': this.listaCursos, 'produtos': this.listaScripts, 'idDono': null, 'ordenacao': 0, 'conteudo': ''
         }
         
         if (pacote == null) {
            this.$store.dispatch('searchAllScripts')
            this.$store.dispatch('searchAllCursos')
            $('#modalPacote').modal('show')

         } else {
            this.$store.dispatch('alternarTelaCarregamento', true)

            this.$axios({
               method: 'get',
               url: this.urlRest +'servicosAdmin/getPacote',
               params: {
                  id: pacote.id
               }
            }).then(response => {
               this.modalPacote = response.data
               this.modalPacote.tipo = JSON.parse(this.modalPacote.tipo)
               this.modalPacote.valor = parseFloat(this.modalPacote.valor).toFixed(2)
               this.calcularValores(this.modalPacote)
   
               $('#modalPacote').modal('show')
   
            }).catch((error) => {
               if (error.response != undefined) {
                  if (error.response.status == 408) {
                     this.$store.dispatch('deslogar')
   
                     this.$toast.fire({
                        icon: 'warning',
                        title: this.$t('sessaoExpirada')
                     });
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: 'Erro: ' + error.response.status
                     });
                  }
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: error
                  });
               }
            }).finally(() => {
               this.$store.dispatch('alternarTelaCarregamento', false)
            });
         }
      },
		deletarPacote : function (pacote) {
         Swal.fire({
            icon: 'warning',
            title: this.$t('admGerenciar.deletarPacoteDesc'),
            showCancelButton: true,
            confirmButtonText: this.$t('admGerenciar.botaoConfirmar'),
            cancelButtonText: this.$t('admGerenciar.botaoCancelar')

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)
               
               this.$axios({
                  method: 'post',
                  url: this.urlRest +'servicosAdmin/deletePacote',
                  params: {
                     id: pacote.id
                  }
               }).then(() => {
                  this.searchPacotes()

                  this.$toast.fire({
                     icon: 'success',
                     title: this.$t('admGerenciar.deletarPacoteSucesso')
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: this.$t('sessaoExpirada')
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
		},
      vinculo : function (item, pacote) {
         item.vinculo = !item.vinculo
         this.valorRecomendado = 0,
         this.calcularValores(pacote)
      },
      calcularValores : function (pacote) {
         for (var i = 0; i < pacote.modulos.length; i++) {
            if (pacote.modulos[i].vinculo) {
               this.valorRecomendado += pacote.modulos[i].valor;
            }
         }

         for (var h = 0; h < pacote.produtos.length; h++) {
            if (pacote.produtos[h].vinculo) {
               this.valorRecomendado += pacote.produtos[h].valor;
            }
         }
      },
      searchCursos : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.pesquisa.resultado = [];

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicosAdmin/searchCursos',
            params: {
               nome: this.pesquisa.nome
            }
         }).then(response => {
				this.pesquisa.resultado = response.data;

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t('sessaoExpirada')
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
		saveCurso : function () {
         if (this.modalModulo.nome == null || this.modalModulo.nome.trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: this.$t('admGerenciar.validacaoTitulo')
            })

            return
         } else if (this.modalModulo.codigo == null || this.modalModulo.codigo.trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: this.$t('admGerenciar.validacaoCodigo')
            })

            return
         }

			let dataForm = new FormData();
         dataForm.append('info', JSON.stringify(this.modalModulo));

         for (let file of this.$refs.cursoFiles.files) {
            if (file.size < 10485760) {
               dataForm.append('banner', file, String(file.name).replace(/[^\w\s.]/gi, ''));
            } else {
               Swal.fire({
                  icon: 'error',
                  title: this.$t('admGerenciar.validacaoTamanhoArquivo'),
                  text: this.$t('admGerenciar.validacaoTamanhoArquivoDesc')
               });
               
               return
            }
         }

         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicosAdmin/saveCurso',
            headers: {
					'Content-Type': 'multipart/form-data'
            },
				data: dataForm

         }).then(() => {
            this.searchCursos()

            this.$toast.fire({
               icon: 'success',
               title: this.$t('admGerenciar.cursoSalvoSucesso')
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t('sessaoExpirada')
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            $('#modalCurso').modal('hide')

            this.$refs.cursoFiles.value = null;
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
		abrirModalCurso : function (modulo) {
         if (modulo) {
            this.modalModulo = JSON.parse(JSON.stringify(modulo));
            if (this.modalModulo.valor != null) this.modalModulo.valor = parseFloat(this.modalModulo.valor).toFixed(2)

         } else {
            this.modalModulo = {
               'id': null, 'nome': null, 'codigo': null, 'pontuacao': 0, 'qtdVotos': 0, 'descricao': null, 'nomeArquivo': null, 'valor': '0.00', 'urlImage': null, 
               'media': 5, 'vinculo': false, 'blocos': []
            }
         }

         $('#modalCurso').modal('show')
      },
		deleteCurso : function (documento) {
         Swal.fire({
            icon: 'warning',
            title: this.$t('admGerenciar.deletarCursoDesc'),
            showCancelButton: true,
            confirmButtonText: this.$t('admGerenciar.botaoConfirmar'),
            cancelButtonText: this.$t('admGerenciar.botaoCancelar')

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)
               
               this.$axios({
                  method: 'post',
                  url: this.urlRest +'servicosAdmin/deleteCurso',
                  params: {
                     id: documento.id
                  }
               }).then(() => {
                  this.searchCursos()

                  this.$toast.fire({
                     icon: 'success',
                     title: this.$t('admGerenciar.deletarCursoSucesso')
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: this.$t('sessaoExpirada')
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
		},
      abrirModulo : function (modulo) {
         this.$router.push('/admModulo/' + modulo.id);
      },
      searchScripts : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.pesquisa.resultado = [];

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicosAdmin/searchScripts',
            params: {
               nome: this.pesquisa.nome
            }
         }).then(response => {
				this.pesquisa.resultado = response.data;

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t('sessaoExpirada')
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      abrirModalScript : function (script) {
         if (script) {
            this.modalScript = JSON.parse(JSON.stringify(script));
            this.modalScript.tipo = JSON.parse(this.modalScript.tipo)

            if (this.modalScript.valor != null) this.modalScript.valor = parseFloat(this.modalScript.valor).toFixed(2)
            
         } else {
            this.modalScript = {
               'link': null, 'linkDriver': null, 'valor': '0.00', 'nome': null, 'senhaSvn': null, 'descricao': null, 'loginSvn': null, 'listFotos': [], 
               'vinculo': false, 'id': null, 'linkSvn': null, 'linkDocumentacao': null, 'urlImage': null, 'isPromocao': false, 'isDesabilitado': false, 'isDestaque': false,
               'isExclusivo': false, 'isOtimizado': false, 'isTestado': false, 'isSeguro': false, 'tipo': [], 'idDono': null, 'ordenacao': 0, 'conteudo': ''
            }
         }
         
         this.showPassword = false
         $('#modalScript').modal('show')
      },
      deleteScript : function (script) {
         Swal.fire({
            icon: 'warning',
            title: this.$t('admGerenciar.deletarScriptDesc'),
            showCancelButton: true,
            confirmButtonText: this.$t('admGerenciar.botaoConfirmar'),
            cancelButtonText: this.$t('admGerenciar.botaoCancelar')

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)
               
               this.$axios({
                  method: 'post',
                  url: this.urlRest +'servicosAdmin/deleteScript',
                  params: {
                     id: script.id
                  }
               }).then(() => {
                  this.searchScripts()

                  this.$toast.fire({
                     icon: 'success',
                     title: this.$t('admGerenciar.deletarScriptSucesso')
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: this.$t('sessaoExpirada')
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
		},
      saveScript : function () {
         if (this.modalScript.nome == null || this.modalScript.nome.trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: this.$t('admGerenciar.validacaoTitulo')
            })

            return
         } else if (this.modalScript.tipo == null || this.modalScript.tipo.length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: this.$t('admGerenciar.validacaoCategoria')
            })

            return
         }

			let dataForm = new FormData();
         dataForm.append('info', JSON.stringify(this.modalScript));

         if ((this.$refs.imagemFile.files.length + (this.modalScript.listFotos == null ? 0 : this.modalScript.listFotos.length)) > 3) {
            Swal.fire({
               icon: 'error',
               title: this.$t('admGerenciar.validacaoLimiteImagens'),
               text: this.$t('admGerenciar.validacaoLimiteImagensDesc')
            });

            this.$refs.imagemFile.value = "";
            return
         }
         
         for (let file of this.$refs.scriptFile.files) {
            if (file.size < 10485760) {
               dataForm.append('banner', file, String(file.name).replace(/[^\w\s.]/gi, ''));
            } else {
               Swal.fire({
                  icon: 'error',
                  title: this.$t('admGerenciar.validacaoTamanhoArquivo'),
                  text: this.$t('admGerenciar.validacaoTamanhoArquivoDesc')
               });
               
               return
            }
         }

         for (let file of this.$refs.imagemFile.files) {
            if (file.size < 10485760) {
               dataForm.append('imagem', file, String(file.name).replace(/[^\w\s.]/gi, ''));
            } else {
               Swal.fire({
                  icon: 'error',
                  title: this.$t('admGerenciar.validacaoTamanhoArquivo'),
                  text: this.$t('admGerenciar.validacaoTamanhoArquivoDesc')
               });
               
               return
            }
         }

         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicosAdmin/saveScript',
            headers: {
					'Content-Type': 'multipart/form-data'
            },
				data: dataForm

         }).then(() => {
            this.searchScripts()
            
            this.$toast.fire({
               icon: 'success',
               title: this.$t('admGerenciar.scriptSalvoSucesso')
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t('sessaoExpirada')
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            $('#modalScript').modal('hide')

            this.$refs.scriptFile.value = null;
            this.$refs.imagemFile.value = null;
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
      removeImage : function (index) {
         this.modalScript.listFotos.splice(index, 1);
      }
	},
   created() {
      let tipoSalvo = localStorage.getItem('ultimaOpcaoSelecionada');

      if (tipoSalvo) {
         this.pesquisa.tipo = tipoSalvo;
      } else {
         this.pesquisa.tipo = 'Pacotes';
      }
   },
	mounted() {
      this.$store.dispatch('searchAllScripts')
      this.$store.dispatch('searchAllCursos')
      this.$store.dispatch('buscarDonosScript')
		this.search()
	}
}

</script>

<style scoped>

.form-check-label {
   margin-top: 2px;
}

.toggle-password {
   position: absolute;
   top: 73%;
   transform: translateY(-50%);
   right: 16px;
   cursor: pointer;
}

</style>